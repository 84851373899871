"use client";

import { actionProductList } from "@/actions/product";
import Banner3 from "@/assets/images/banner-6.png";
import Footer from "@/components/Footer";
import GoodCard from "@/components/GoodCardNew";
import { useApp } from "@/models/app";
import { IResPage } from "@/requests";
import { IProduct } from "@/requests/product";
import { omitProduct } from "@/utils";
import { i_blog } from "@prisma/client";
import { useRequest, useThrottleFn } from "ahooks";
import { Carousel, Drawer } from "antd";
import classNames from "classnames";
import { unionBy } from "lodash";
import { observer } from "mobx-react-lite";
import Image from "next/image";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const PageMain: React.FC<{
	search?: string;
	initProductList?: IResPage<IProduct>;
	pageSize?: number;
	promotionList?: IResPage<any>;
	banners?: Partial<i_blog>[];
	selectedCategory?: string;
}> = observer(
	({
		search,
		banners,
		selectedCategory,
		initProductList,
		pageSize = 20,
		promotionList,
	}) => {
		const MApp = useApp();
		const [dataAll, setData] = useState<any[]>(
			initProductList?.data.list || []
		);
		const page = useRef(initProductList ? 2 : 1);
		const total = useRef(initProductList?.data.total || 0);
		const categoryId = useMemo(
			() =>
				MApp.menus?.find((item) => item.name === selectedCategory || "")?.ID ||
				undefined,
			[]
		);

		const [hasMore, setHasMore] = useState(true);
		const [totalProducts, setTotalProducts] = useState(total.current);
		const searchParams = useSearchParams();
		const searchKeyword = searchParams.get("search");
		const isSearchResult = useMemo(() => !!searchKeyword, [searchKeyword]);
		const { run, loading } = useRequest(actionProductList, {
			manual: true,
			onSuccess: (res) => {
				const r = res.data;
				setData((prevData) =>
					unionBy(
						[...prevData, ...(r?.list.map(omitProduct) || [])],
						(item) => item.original_id
					)
				);
				total.current = r.total;
				setTotalProducts(r.total);
				page.current += 1;
				if (r.total <= r.page * r.pageSize) {
					setHasMore(false);
				}
			},
		});

		const loadMoreData = async () => {
			run({
				page: page.current,
				pageSize,
				category_id: categoryId,
				keyword: searchKeyword,
			});
		};
		const scrollContainerRef = useRef<Array<HTMLDivElement | null>>([]);

		const goNext = (index: number) => {
			const container = scrollContainerRef.current[index];
			if (container) {
				container.scrollBy({ left: container.clientWidth, behavior: "smooth" });
			}
		};

		const goPrev = (index: number) => {
			const container = scrollContainerRef.current[index];
			if (container) {
				container.scrollBy({
					left: -container.clientWidth,
					behavior: "smooth",
				});
			}
		};

		const { run: handleScroll } = useThrottleFn(
			(event: any) => {
				const diff = Math.abs(
					window.innerHeight -
						(event.target.scrollHeight - event.target.scrollTop)
				);
				if (diff < 4000) {
					if (
						loading ||
						(page.current - 1) * pageSize >= total.current ||
						total.current == 0
					)
						return;
					loadMoreData();
				}
				if (event.target.scrollTop > 200) {
					setIsFixed(true);
				} else {
					setIsFixed(false);
				}
			},
			{ wait: 100 }
		);
		useEffect(() => {
			const element: any = document.getElementById("root");
			element?.addEventListener("scroll", handleScroll);
			return () => {
				element?.removeEventListener("scroll", handleScroll);
			};
		}, []);

		const { t } = useTranslation();
		const carouselImages = [Banner3];
		const [currentIndex, setCurrentIndex] = useState(0);
		const getCarouselStyle = () => {
			return {
				transform: `translateX(-${
					(currentIndex * 100) / carouselImages.length
				}%)`,
				width: `${carouselImages.length * 100}%`,
			};
		};
		const changeSlider = (num: number) => {
			if (num == 1) {
				setCurrentIndex(
					(currentIndex - 1 + carouselImages.length) % carouselImages.length
				);
			} else {
				setCurrentIndex((currentIndex + 1) % carouselImages.length);
			}
		};
		const [selectedIndex, setSelectedIndex] = useState(0);
		const [showMenu, setShowMenu] = useState(false);
		const [subList, setSubList] = useState<any[]>([]);
		const [isFixed, setIsFixed] = useState(false);

		return (
			<div>
				<Drawer
					open={showMenu}
					onClose={() => setShowMenu(false)}
					placement="left"
					closable={false}
					styles={{ body: { padding: 0 } }}
				>
					<div id="menuContainer">
						<div className={styles["menu-title"]}>
							<p>Menu</p>
							<div
								className={classNames(styles["icon-btn"], styles["icon-close"])}
								onClick={() => setShowMenu(false)}
							></div>
						</div>
						<div className={styles["directory-container"]}>
							<div
								onClick={() => {
									subList.pop();
									setSubList([...subList]);
								}}
								className={classNames(styles["btn-back"], {
									[styles["show"]]: !!subList?.length,
								})}
							>
								BACK
							</div>

							<ul className={styles["catagory-list"]}>
								{MApp.menus?.map((cata, index) => {
									return (
										<li key={"menu" + index}>
											<div
												onClick={() => {
													MApp.setLikeMenus(
														cata.like
															? MApp.likeMenus.filter((i) => i !== index)
															: [...MApp.likeMenus, index]
													);
												}}
												className={classNames(
													styles["cata-list-btn"],
													"btn-like"
												)}
											>
												<div
													className={classNames(styles["icon-btn"], {
														"icon-no-like-goods": !cata.like,
														"icon-like-goods": cata.like,
													})}
												></div>
											</div>
											<Link
												className={classNames(styles["cata-title"], {
													[styles["selected"]]: selectedIndex == index + 1,
												})}
												href={"/" + encodeURIComponent(cata.name)}
											>
												{cata.name}
											</Link>
											{!!cata.subs?.length && (
												<div
													className={classNames(styles["cata-list-btn"], [
														"btn-enter",
													])}
													onClick={() => {
														subList?.push(cata.subs);
														setSubList([...subList]);
													}}
												>
													<div className="icon-btn icon-enter"></div>
												</div>
											)}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</Drawer>
				<div className={styles["layout-container"]}>
					<div className={styles["main-container"]}>
						{/* <div className="side-bar">
            <div id="openTopyBtn">
              <div className="icon-topy"></div>
            </div>
            <div id="openLikeBtn">
              <div className="icon-like"></div>
            </div>
            <div id="openRankBtn">
              <div className="icon-rank"></div>
            </div>
            <div id="openWishlistBtn">
              <div className="icon-wishlist"></div>
            </div>
            <div id="toTopBtn">
              <div className="icon-toTop"></div>
            </div>
          </div> */}
						<div
							className={classNames(
								styles["secondery-nav"],
								"user-select-none",
								{
									[styles["fixed"]]: isFixed,
								}
							)}
						>
							<div
								className={styles["cata-btn"]}
								onClick={() => setShowMenu(!showMenu)}
							>
								<div className="icon-list"></div>
							</div>
							<div className={styles["secondery-nav-tab"]}>
								{[{ name: "Hot Deals" }]
									.concat(
										selectedCategory && selectedCategory !== "Hot Deals"
											? [{ name: selectedCategory }]
											: []
									)
									.concat(
										MApp.menus
											?.filter((item) => item.name !== selectedCategory)
											.slice(0, selectedCategory ? 4 : 5)
									)
									?.map((item: any, index) => {
										return (
											<Link
												href={
													index === 0
														? "/"
														: "/" + encodeURIComponent(item.name)
												}
												key={"menu1" + index}
												className={classNames({
													[styles["selected"]]: selectedCategory
														? item.name === selectedCategory
														: index === 0,
												})}
											>
												{item.name}
											</Link>
										);
									})}
							</div>
						</div>
						{banners?.length ? (
							<Carousel
								className={styles["carousel-container"]}
								arrows
								infinite
							>
								{banners.map((banner, index) => (
									<Link
										href={`/blog/${banner.slug}`}
										key={banner.slug}
										className={styles["carousel-image"]}
									>
										<Image
											fill
											objectFit="contain"
											key={index}
											priority={index === 0}
											src={banner.image || ""}
											alt={banner.title || "banner"}
										/>
									</Link>
								))}
							</Carousel>
						) : undefined}
						{!isSearchResult &&
							(!selectedCategory || selectedCategory == "Hot Deals") && (
								<div>
									{promotionList?.data.list?.map((i, index) => {
										return (
											<div
												data-content="promotedSec"
												className={styles["promoted-goods-sec"]}
												key={index}
											>
												<p className={styles["module-title"]}>{i.title}</p>
												<div className={styles["promoted-container"]}>
													<button
														aria-label="prev"
														className={classNames(
															styles["promoted-pager"],
															styles["prev-button"]
														)}
														onClick={() => goPrev(index)}
													>
														<i className="icon icon-prev"></i>
													</button>
													<div
														id={"scrollableArea" + index}
														className={styles["scrollable-area"]}
														ref={(el) => {
															scrollContainerRef.current[index] = el;
														}}
													>
														<div className={styles["promoted-panel"]}>
															{i.products?.map((item: any, index2: number) => {
																return (
																	<GoodCard
																		priority={index2 === 0}
																		item={item}
																		key={"promotion" + index + index2}
																	/>
																);
															})}
														</div>
													</div>
													<button
														aria-label="next"
														className={classNames(
															styles["promoted-pager"],
															styles["next-button"]
														)}
														onClick={() => goNext(index)}
													>
														<i className="icon icon-next"></i>
													</button>
												</div>
											</div>
										);
									})}
								</div>
							)}

						<div className={styles["module-title"]}>
							{isSearchResult ? (
								<span>
									{totalProducts} results found matching <b>{searchKeyword}</b>
								</span>
							) : selectedCategory && selectedCategory != "Hot Deals" ? (
								""
							) : (
								"New Today"
							)}
						</div>
						<div className={styles["products-list"]}>
							{dataAll.map((item: any, index: number) => {
								return (
									<GoodCard
										item={item}
										key={"good" + index}
										priority={index < 3}
									/>
								);
							})}
						</div>
						{!hasMore && <p id="datafinishedTip">no more products</p>}

						{loading && (
							<div className={styles["loading-indicator"]}>
								<div className={styles["loading-circle"]}></div>
								<div className={styles["loading-circle"]}></div>
								<div className={styles["loading-circle"]}></div>
							</div>
						)}
					</div>

					{/* <div id="sideContainer" className={classNames("side-container",{show:showChat})}>
          <button
            id="chatAICloseBtn"
            className="icon-btn icon-close popup-close"
          ></button>
        </div> */}
				</div>
				<Footer fixed={isFixed} />
			</div>
		);
	}
);

export default PageMain;
