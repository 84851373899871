import { pick } from "lodash";

export const omitProduct = (product: any) => {
	product.likesCount = Object.values(product?.AI_From_Review || {})
		.map((i: any) => parseInt(i?.split?.(" ")?.[0]))
		.reduce((sum, value) => sum + value, 0);
	product.reviewsCount = product.reviews?.length || 0;
	return pick(product, [
		"reviewsCount",
		"likesCount",
		"original_id",
		"ID",
		"ref_platform_id",
		"redTip",
		"product_original_price",
		"product_photo",
		"product_star_rating",
		"product_num_ratings",
		"rank",
		"sales_volume",
		"product_title",
		"product_price",
		"product_url",
		"about_product",
	]);
};
